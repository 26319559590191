/* eslint-disable no-unused-vars */
import React from 'react';
import CustomeRadio from './radio.js';
import TopicsMultiSelect from './multiselect';
import TopicContentMap from '../topicContentMap';
import Comments from './comments';

const ContentForm = (props) => {

  const{
    contentType,
    title,
    description,
    thumbnailURL,
    heroURL,
    pollOptions,
    renderer,
    check,
    setContentType,
    setTitle,
    setDescription,
    setThumbnailURL,
    setHeroURL,
    setPollOptions,
    reRender,
    setCheck,
    actionCreateContent,
    addTopic,
    topics,
    selectedContent,
    actionUpdateContent,
    actionDeleteContent,
    clearForm,
    onFileChange,
    thumnailFileImage,
    uploadFile,
    heroFileImage,
    thumnailUploadStatus,
    heroUploadStatus,
    setContentURL,
    setCaptions,
    setValidity,
    setReadTime,
    contentURL,
    captions,
    readTime,
    validity,
    setTopicId,
    allcontentData,
    uploadingImage
  } = props;

  const addPollOptions = () => {
    const emptyObj = {name: '', count: 0}
    const currentPoll = pollOptions;
    currentPoll.push(emptyObj);
    setPollOptions(currentPoll);
    reRender(!renderer);
  }

  const deletePollOptions = (i) => {
    const currentPoll = pollOptions;
    currentPoll.splice(i,1)
    setPollOptions(currentPoll);
    reRender(!renderer);
  }
  
  const updatePollOptions = (e,i) => {
    const currentPoll = pollOptions;
    currentPoll[i].name = e.target.value;
    setPollOptions(currentPoll);
    reRender(!renderer);
  }
  return(
    <div style={{"padding": '14px'}}>
      
      <div>
        <div className="create_content_form__Heading">Topics</div>
        {/* <TopicsMultiSelect
          options="labtest"
          dataSet={topics.data}
          setData={(e) => addTopic(e)}
          //parentRef={labtestRef}
        /> */}
        <TopicContentMap key={title || 'New'} selectedContent={selectedContent} setTopicId={setTopicId}/>
        <div class="create_content__border"></div>
      </div>
      <div className="create_content_form__Heading">General</div>
      <div className="row">
        <div className="col-12 ">
            <form className="form-horizontal">
              <div className="row mb-4 mt-4">
                <label className="col-2 col-form-label">Content Type</label>
                <div className="col-10">
                  <select value={contentType} onChange={(e) => setContentType(e.target.value)} style={{ padding: 8, borderRadius: 4, borderColor: '#dee2e6', cursor: 'pointer'}} disabled={selectedContent ? true : false}>
                    <option value="poll" style={{padding: 8}}>Poll</option>
                    <option value="video" style={{padding: 8}}>Video</option>
                    <option value="blog" style={{padding: 8}}>Blog</option>
                    <option value="post" style={{padding: 8}}>Post</option>
                    {/* <option value="recipie" style={{padding: 8}}>Recipie</option> */}
                  </select>
                </div>
              </div>
                {
                  contentType !== 'post' ?
                    <div className="row mb-4">
                      <label
                          className="col-2 col-form-label"
                      >
                          Title
                      </label>
                      <div className="col-10">
                          <input
                              maxLength={200}
                              type="text"
                              className="form-control"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value)
                              }}
                              id="simpleInput1"
                              placeholder="Title"
                              autoComplete="off"
                          />
                      </div>
                  </div> : null
                }
                

                {false &&
                  <div className="row mb-4">
                      <label
                          className="col-2 col-form-label"
                      >
                          Caption
                      </label>
                      <div className="col-10">
                          <input
                              type="text"
                              className="form-control"
                              value={captions}
                              maxLength={200}
                              onChange={(e) => {
                                setCaptions(e.target.value)
                              }}
                              id="simpleInput1"
                              placeholder="Caption"
                              autoComplete="off"
                          />
                      </div>
                  </div>
                }
                {
                  contentType !== 'video' && contentType !== 'post' ?
                    <div className="row mb-4">
                      <label
                          htmlFor="simpleInput3"
                          className="col-2 col-form-label"
                      >
                          Description
                      </label>
                      <div className="col-10">
                          <textarea
                            id="simpleInputDesction"
                            class="form-control"
                            rows="3"
                            placeholder="What's up?"
                            value={description}
                            maxLength={200}
                            onChange={(e) => {
                              setDescription(e.target.value)
                            }}
                          ></textarea>
                      </div>
                  </div> : null
                }
                {
                  contentType === 'video' || contentType === 'blog' ?
                    <div className="row mb-4">
                      <label
                          className="col-2 col-form-label"
                      >
                          Content URL
                      </label>
                      <div className="col-10">
                          <input
                              type="text"
                              className="form-control"
                              value={contentURL}
                              maxLength={200}
                              onChange={(e) => {
                                setContentURL(e.target.value)
                              }}
                              id="VideoURL"
                              placeholder="Video / Blog URL"
                              autoComplete="off"
                          />
                      </div>
                  </div> : null
                }
                {
                  contentType === 'poll' ?
                  <div className="row mb-4">
                    <label className="col-2 col-form-label">
                      Validity in days
                    </label>
                    <div className="col-10">
                      <input
                        type="number"
                          className="form-control"
                          value={validity}
                          onChange={(e) => {
                            setValidity(e.target.value)
                          }}
                          id="VideoURL"
                          placeholder="In days eg. 7"
                          autoComplete="off"
                      />
                    </div>
                  </div> : null
                }
                {
                  contentType === 'video' || contentType === 'blog' ?
                    <div className="row mb-4">
                      <label
                          className="col-2 col-form-label"
                      >
                          Read / Video time
                      </label>
                      <div className="col-10">
                          <input
                              type="text"
                              className="form-control"
                              value={readTime}
                              onChange={(e) => {
                                setReadTime(e.target.value)
                              }}
                              id="VideoURL"
                              placeholder="In minutes eg. 5"
                              autoComplete="off"
                          />
                      </div> 
                  </div> : null
                }


               
                {
                  contentType !== 'video' ?  
                  [
                    false && 
                    <div className="row mb-4">
                  <label
                    htmlFor="simpleInput3"
                    className="col-2 col-form-label"
                  >
                    Thumbnail URL
                  </label>
                  {
                    heroUploadStatus === 1 && thumnailUploadStatus !== 2 ? <div className="col-10">Please finish uploading Hero imgae</div> : 
                      <div className="col-10">
                          {
                            thumnailFileImage || thumbnailURL ? 
                            <img src= { thumnailFileImage ? URL.createObjectURL(thumnailFileImage) : thumbnailURL} style={{width: '200px', height: undefined, marginBottom: '20px'}} alt='no' /> : null
                          }
                          

                          <input 
                            type="file" 
                            accept=".png, .jpg, .jpeg"
                            onChange={(event)=>{onFileChange(event, 'thumbnail')}}
                            style={{display:'block',}}
                          />

                          {
                            thumnailFileImage ?
                            <div style={{marginTop: '20px'}}>
                              <button
                                type="button"
                                className="btn"
                                style={{ backgroundColor: "#373750" }}
                                onClick={() => {uploadFile('thumbnail')}}
                              >
                                <span style={{ color: "#FFF" }}>
                                  Upload
                                </span>
                              </button>
                            </div> : null
                          }
                          
                      </div>
                  }
                </div>,
                <div className="row mb-4">
                    <label
                        htmlFor="simpleInput3"
                        className="col-2 col-form-label"
                    >
                        Upload Image
                    </label>
                    {
                    thumnailUploadStatus === 1 && heroUploadStatus !== 2 ? <div className="col-10">Please finish uploading Thumbnail imgae</div> : 
                      <div className="col-10">
                          {
                            heroFileImage || heroURL ? 
                            <img src= {heroFileImage ? URL.createObjectURL(heroFileImage) : heroURL} style={{width: '200px', height: undefined, marginBottom: '20px'}} alt='no' /> : null
                          }
                          
                          <input 
                            type="file" 
                            accept=".png, .jpg, .jpeg"
                            onChange={(event)=>{onFileChange(event, 'hero')}}
                            style={{display:'block'}}
                          />

                          {
                            heroFileImage ? 
                            <div style={{marginTop: '20px'}}>
                              <button
                                type="button"
                                className="btn"
                                style={{ backgroundColor: "#373750" }}
                                onClick={() => {uploadFile('hero')}}
                              >
                                <span style={{ color: "#FFF" }}>
                                  Upload
                                </span>
                              </button>
                            </div> : null
                          }
                          
                      </div>
                    }
                </div>
                  ] : null
                }
                
                
                <div class="create_content__border"></div>
                {
                  contentType === 'poll' ?
                  <div>
                    <div className="create_content_form__Heading">Poll options</div>
                    {
                      pollOptions.length ? pollOptions.map((i, index) => {
                        return(
                          <div className="create_content_form__poll_item">
                            <div className="col-6">
                                <input
                                    maxLength={200}
                                    type="text"
                                    className="form-control"
                                    value={i.name}
                                    onChange={(e) => {
                                      updatePollOptions(e, index)
                                    }}
                                    id="simpleInput1"
                                    placeholder="Poll option"
                                    autoComplete="off"
                                />
                            </div>
                            <div>
                              
                            </div>
                            <div className="create_content_form__buttons_width">
                              <button
                                type="button"
                                className="btn btn-danger"
                                style={{width: '100%'}}
                                onClick={() => {deletePollOptions(index)}}
                              >
                                <span style={{ color: "#FFF" }}>
                                  Delete
                                </span>
                              </button>
                            </div>
                          </div>
                        )
                      }) : <div>No polls added yet</div>
                    }
                    <div style={{display: 'flex', width: '100%', flexDirection: "row-reverse"}}>
                      <div className="create_content_form__buttons_width">
                        <button
                          type="button"
                          className="btn"
                          style={{ backgroundColor: "#373750", width: '100%' }}
                          onClick={() => {
                            addPollOptions()
                          }}
                        >
                          <span style={{ color: "#FFF" }}>
                            Add
                          </span>
                        </button>
                      </div>
                    </div>
                    <div class="create_content__border"></div>
                  </div> : null
                }
                
                <div>
                  <div className="create_content_form__Heading">Community</div>
                  <div>
                    <CustomeRadio check={check} setCheck={setCheck} name={"Enable comments"}/>
                  </div>
                </div>
                <div class="create_content__border"></div>
                
                <div className="create_content_form__Heading">Actions</div>
                <div className="create_content_form__buttons_containr">
                  <div className="create_content_form__buttons_width">
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ width: '100%' }}
                      onClick={() => {
                        if(selectedContent){
                          actionDeleteContent();
                        } else {
                          clearForm();
                        }
                      }}
                    >
                      <span style={{ color: "#FFF" }}>
                        { selectedContent ? "Delete" : "Discard" }
                      </span>
                    </button>
                  </div>
                  
                  <div className="create_content_form__buttons_width">
                    <button
                      type="button"
                      className="btn"
                      disabled={uploadingImage}
                      style={{ backgroundColor: "#373750", width: '100%' }}
                      onClick={() => {
                        if(selectedContent){
                          actionUpdateContent()
                        } else {
                          actionCreateContent()
                        }
                      }}
                    >
                      <span style={{ color: "#FFF" }}>
                        { selectedContent ? "Update" : "Save" }
                      </span>
                    </button>
                  </div>
                  
                </div>
            </form>
            {
              allcontentData ?
                <div>
                  <div class="create_content__border"></div>
                  <div className="create_content_form__Heading">All Data</div>
                  {/* content_id */}
                  <div className="row mb-4">
                    <label className="col-2 col-form-label">
                          Content Id
                      </label>
                      <div className="col-10">
                          <input
                              type="text"
                              className="form-control"
                              value={allcontentData.content_id}
                              id="VideoURL"
                              placeholder="NA"
                              autoComplete="off"
                              disabled
                          />
                    </div>
                  </div>
                  {/* content_url */}
                  <div className="row mb-4">
                    <label className="col-2 col-form-label">
                          Content URL
                      </label>
                      <div className="col-10">
                          <input
                              type="text"
                              className="form-control"
                              value={allcontentData.content_url}
                              id="VideoURL"
                              placeholder="NA"
                              autoComplete="off"
                              disabled
                          />
                    </div>
                  </div>
                  {/* comment_count */}
                  <div className="row mb-4">
                    <label className="col-2 col-form-label">
                          Comment count
                      </label>
                      <div className="col-10">
                          <input
                              type="text"
                              className="form-control"
                              value={allcontentData.comment_count}
                              id="VideoURL"
                              placeholder="NA"
                              autoComplete="off"
                              disabled
                          />
                    </div>
                  </div>
                  {/* is_published */}
                  {/* <div className="row mb-4">
                    <label className="col-2 col-form-label">
                          Is Published
                      </label>
                      <div className="col-10">
                          <input
                              type="text"
                              className="form-control"
                              value={allcontentData.is_published}
                              id="VideoURL"
                              placeholder="NA"
                              autoComplete="off"
                              disabled
                          />
                    </div>
                  </div> */}
                  {/* like_count */}
                  <div className="row mb-4">
                    <label className="col-2 col-form-label">
                          Like Count
                      </label>
                      <div className="col-10">
                          <input
                              type="text"
                              className="form-control"
                              value={allcontentData.like_count}
                              id="VideoURL"
                              placeholder="NA"
                              autoComplete="off"
                              disabled
                          />
                    </div>
                  </div>
                  {/* share_count */}
                  <div className="row mb-4">
                    <label className="col-2 col-form-label">
                          Share count
                      </label>
                      <div className="col-10">
                          <input
                              type="text"
                              className="form-control"
                              value={allcontentData.share_count}
                              id="VideoURL"
                              placeholder="NA"
                              autoComplete="off"
                              disabled
                          />
                    </div>
                  </div>
                  {/* validity */}
                  {/* <div className="row mb-4">
                    <label className="col-2 col-form-label">
                          Validity
                      </label>
                      <div className="col-10">
                          <input
                              type="text"
                              className="form-control"
                              value={allcontentData.validity}
                              id="VideoURL"
                              placeholder="NA"
                              autoComplete="off"
                              disabled
                          />
                    </div>
                  </div> */}
                  {/* view_count */}
                  {/* <div className="row mb-4">
                    <label className="col-2 col-form-label">
                          View count
                      </label>
                      <div className="col-10">
                          <input
                              type="text"
                              className="form-control"
                              value={allcontentData.view_count}
                              id="VideoURL"
                              placeholder="NA"
                              autoComplete="off"
                              disabled
                          />
                    </div>
                  </div> */}
                </div> : null}
        </div>
            </div>
    </div>
  )
}

export default ContentForm;