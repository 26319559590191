/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect, useState, useRef, useMemo} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Navbar from '../navbar'
import { getLeaderboardSnapshot, getChallengeDetails, addUserInLeaderboard } from '../../actions/challenges';
import { leaderboardSelector, challengeDetailsSelector } from '../../selector/challenges';
import { usersSelector } from '../../selector/users';
import { getAllUsers } from '../../actions/users';
import { useHistory } from 'react-router';
import moment from 'moment';
import SelectSearch, { fuzzySearch } from 'react-select-search';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

const Action = (props) => {
    const history = useHistory()
    const {userId,challengeId} = props.data.action
    return (
        <>
                <div className="row">
                 <div className="col-3">
                          <i
                              onClick={() => history.push(`/challenge/${challengeId}/user/${userId}`)}
                              className="mdi mdi-account text-info"
                          ></i>

                 </div>
                </div>
            </>
    )
}


const Table = ({challengeId}) =>{  
    const dispatch = useDispatch()
    const gridRef = useRef();
    useEffect(()=>{
        dispatch(getLeaderboardSnapshot(challengeId))
    },[dispatch, challengeId])
    
    const leaderboard = useSelector((state) => leaderboardSelector(state));
    
    const data = leaderboard?leaderboard.map((user) => {
        return {
            userId: user.userId,
            userName: user.firstName,
            contact: user.callingNumber,
            planName: user.planName,
            startDate: moment(user.planStartDate).format('DD-MM-YYYY'),
            remaining: (user.planName!=='Free Plan' && moment(user.planEndDate).diff(moment(),'days')>0) ?  `${moment(user.planEndDate).diff(moment(),'days')} days`: `∞`,
            totalRewards: user.totalRewards,
            currentStandings: user.currentStandings,
            action: {userId: user.userId,challengeId: user.challengeId}


        }
    }):[]

    const popupParent = useMemo(() => {
        return document.body;
      }, [])

    const onBtnExport = () => {
        const exportCols = {
            columnKeys: ['userId','userName', 'contact', 'totalRewards','currentStandings','planName', 'startDate', 'remaining',],
            csvExportParams: {
                processCellCallback: (params) => {
                  if (typeof params.value === 'string' && /^[=+\-@]/.test(params.value)) {
                    return `'${params.value}`; // prepending a single quote to mitigate CSV injection
                  }
                  return params.value;
                }
              }
        };
        gridRef.current.api.exportDataAsCsv(exportCols);
    }



    const [columnDefs] = useState([
        {headerName:"User ID", field:"userId", resizable:true, sortable:true, filter:true, width:'120'},
        {headerName:"Name", field:"userName", resizable:true, sortable:true, filter:true,width:'120'},
        {headerName:"Contact", field:"contact", resizable:true, sortable:true, filter:true, width:'120'},
        {headerName:"Challenge Score", field:"totalRewards", resizable:true, sortable:true, filter:true, width:'120'},
        {headerName:"Current Standings", field:"currentStandings", resizable:true, sortable:true, filter:true, width:'120'},
        {headerName:"CurrentPlan", field:"planName", resizable:true, sortable:true, filter:true, width:'120'},
        {headerName:"Plan Start", field:"startDate", resizable:true, sortable:true, filter:true, width:'120'},
        {headerName:"Remaining", field:"remaining", resizable:true, sortable:true, filter:true, width:'120'},
        {headerName:"Actions", field:"action", cellRenderer:Action,resizable:true, sortable:true, filter:true, width:'120'} ,
    ])

    return(
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div id="basic-datatable-wrapper" className="ag-theme-material ag-selectable" style={{height: '100vh'}}>
                                <div className="row d-flex justify-content-end px-3">
                                    <a 
                                        className="btn btn-info ms-2"
                                        onClick={onBtnExport}
                                        style={{width:'45px', height:'45px'}}
                                        
                                        >
                                        <i class="dripicons-download"></i>
                                    </a>
                                    
                                </div>
                                <AgGridReact
                                        ref={gridRef}
                                        rowData={data}
                                        columnDefs={columnDefs}
                                        pagination={true} 
                                        paginationPageSize={20}
                                        cacheQuickFilter={true}
                                        suppressExcelExport={true}
                                        popupParent={popupParent}
                                        enableCellTextSelection={true}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AddUserModal = ({allUsers, challengeId, challengeStartDate, challengeEndDate, dispatch}) => {
    const [userId, setUserId] = useState('')
    
    const options = allUsers?allUsers.map((user)=>({name:`${user.firstName} ${user.lastName} (${user.callingNumber})`,value:user.userId})):[]

    const handleAddUser = (e) => {
        e.preventDefault();
        dispatch(addUserInLeaderboard({userId, challengeId, challengeStartDate, challengeEndDate}));
    }

    useEffect(() => {

    }, [dispatch])
    return (
        <div id="addUserModal" className="modal fade" tabIndex="-1" role="dialog" ariaHidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">

                    <div class="modal-header">
                        <h2 class="modal-title">Add User</h2>
                    </div>

                    <div className="modal-body d-flex justify-content-center">
                        <div>
                            <form className="ps-2 pe-2">
                                <div className="mb-3 mt-4">
                                    <div className="col-4">
                                        <SelectSearch 
                                            options={options}
                                            search
                                            filterOptions={fuzzySearch} 
                                            emptyMessage="Not Found" 
                                            placeholder="Select User"
                                            onChange={(e)=>{setUserId(e)}}     
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 text-center">
                                    <button 
                                        className="btn btn-success" 
                                        type="submit"
                                        data-bs-toggle="modal"
                                        data-bs-target="#addUserModal"
                                        onClick={(e) => {handleAddUser(e)}}
                                    >
                                        Add User
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ChallengeDetails = ({
    challengeName,
    challengeCurrentCapacity,
    challengeId,
    challengeStartDate,
    challengeEndDate,
    allUsers,
    dispatch
}) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card bg-secondary">
                    <div className="card-body profile-user-box">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div>
                                    <div className="row">
                                        <div className="col-8">
                                            <h1 className="mt-1 mb-1 text-white">
                                                {challengeName}
                                            </h1>
                                            <h3 className="text-white-50 mb-1">
                                                Current Capacity: {challengeCurrentCapacity} | {challengeId}
                                            </h3>
                                            <h3 className="text-white mb-1">
                                                Start: {moment(challengeStartDate).format('DD-MM-YYYY')} End: {moment(challengeEndDate).format('DD-MM-YYYY')}
                                            </h3>
                                            
                                        </div>
                                        <div
                                                className="col-4"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                    paddingRight: "2em",
                                                }}
                                            >
                                                <a
                                                    className="btn btn-link"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#addUserModal"
                                                    
                                                >
                                                    <span 
                                                        className="text-white"
                                                    >
                                                        <i className="mdi mdi-pencil"></i>{" "}
                                                        Add User
                                                    </span>
                                                </a>
                                        </div>
                                        <AddUserModal 
                                            allUsers={allUsers}
                                            challengeId={challengeId}
                                            challengeStartDate={moment(challengeStartDate).format('YYYY-MM-DD')}
                                            challengeEndDate={moment(challengeEndDate).format('YYYY-MM-DD')}
                                            dispatch={dispatch}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ChallengeLeaderboard = (props)  => {

    const dispatch = useDispatch()
    const challengeId = props.match.params.challengeId
    useEffect(()=>{
        dispatch(getChallengeDetails(challengeId))
        dispatch(getAllUsers())
    },[dispatch,challengeId])

    const allUsers = useSelector((state)=>usersSelector(state))
    const challengeDetails = useSelector(state=>challengeDetailsSelector(state))

    return (
        <div>
            <Navbar pageTitle={"Leaderboard"}>
                <div>
                    <ChallengeDetails
                        challengeName={challengeDetails.challengeName}
                        challengeCurrentCapacity={challengeDetails.challengeCurrentCapacity}
                        challengeId={challengeDetails.challengeId}
                        challengeStartDate={challengeDetails.challengeStartDate}
                        challengeEndDate={challengeDetails.challengeEndDate}
                        allUsers={allUsers}
                        dispatch={dispatch}
                    />
                </div>
                <Table challengeId={props.match.params.challengeId}/>
            </Navbar>
        </div>
    )
}

export default ChallengeLeaderboard;
