import React,{useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Navbar from '../../navbar'
import Alert from "sweetalert2";
import { changePassword } from '../../../actions/login';
import axios from 'axios';
import { getProfilePresignedUrl, saveProfileImageLocation } from '../../../actions/profilePhotoUpload';
import { profilePhotoPresignedUrlSelector } from '../../../selector/presignedUrlSelector';
import { authDetailsSeletor } from '../../../selector/auth';

const ChangePassword = () =>  {
    const dispatch = useDispatch()

    const [oldPassword,setOldPassword] = useState('')
    const [validateOldPassword,setValidateOldPassword] = useState('')

    const [newPassword,setNewPassword] = useState('')
    const [validateNewPassword,setValidateNewPassword] = useState('')

    const [newPasswordRepeat,setNewPasswordRepeat] = useState('')
    const [validateNewPasswordRepeat,setValidateNewPasswordRepeat] = useState('')

    const handleSubmit = () => {
        if(newPassword!==newPasswordRepeat){
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: 'New Passwords Do Not Match',
                showConfirmButton: false,
                timer: 1500
              })
        }else{
            dispatch(changePassword({oldPassword,newPassword}))
        }
    }
    

    const authDetails = useSelector((state) => authDetailsSeletor(state));
    const [profileImage,setProfileImage] = useState('')
    const [src] = useState(authDetails.profilImahe!==null?authDetails.profileImage:'/assets/images/users/avatar.png')
    
    const onFileChange = (event) => {
        setProfileImage(event.target.files[0])
        dispatch(getProfilePresignedUrl(event.target.files[0].type))
    }
    const uploadConfigs = useSelector(state=>profilePhotoPresignedUrlSelector(state))
    
    
    const uploadFile = async () => {
             await axios.put(uploadConfigs.url,profileImage,{
                 headers:{
                    'Content-Type':profileImage.type
                 }
             }).then((val)=>{
                const uploadUrl = `https://image-store-admin.s3.amazonaws.com/${uploadConfigs.key}`
                dispatch(saveProfileImageLocation({loc:uploadUrl}))
             }).catch((err)=>{
                Alert.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Image Upload Failed',
                    showConfirmButton: false,
                    timer: 1500
                  })
             })
    }
    return (
        <Navbar pageTitle="Change Your Password">
            <div className="mb-3">
                <div className="row">
                    <div className="col-10">
                        <form className="form-horizontal">
                            <input
                                autocomplete="false"
                                name="hidden"
                                type="text"
                                style={{ display: "none" }}
                            />
                            <div className="row mb-4">
                                <label
                                    htmlFor="simpleInput1"
                                    className="col-2 col-form-label"
                                >
                                    Old Password : 
                                </label>
                                <div className="col-10">
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={oldPassword}
                                        onChange={(e) => {
                                            setOldPassword(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidateOldPassword(false)
                                                }else{
                                                    setValidateOldPassword(true)
                                                }
                                            }}
                                        id="simpleInput1"
                                        placeholder="Old Password"
                                        autoComplete="off"
                                    />
                                    {validateOldPassword === false ?(
                                            <div className="text-danger">
                                                Please enter a password
                                            </div>
                                        ):null} 
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label
                                    htmlFor="simpleInput1"
                                    className="col-2 col-form-label"
                                >
                                    New Password : 
                                </label>
                                <div className="col-10">
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={newPassword}
                                        onChange={(e) => {
                                            setNewPassword(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidateNewPassword(false)
                                                }else{
                                                    setValidateNewPassword(true)
                                                }
                                            }}
                                        id="simpleInput1"
                                        placeholder="New Password"
                                        autoComplete="off"
                                    />
                                    {validateNewPassword === false ?(
                                            <div className="text-danger">
                                                Please enter the new Password
                                            </div>
                                        ):null} 
                                         <label style={{ marginTop: '8px'}}>
                                            Password must be between 8 to 16 characters with at least 1 number and 1 special character
                                        </label>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label
                                    htmlFor="simpleInput1"
                                    className="col-2 col-form-label"
                                >
                                    Repeat New Password : 
                                </label>
                                <div className="col-10">
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={newPasswordRepeat}
                                        onChange={(e) => {
                                            setNewPasswordRepeat(e.target.value);
                                        }}
                                        onBlur={(e)=>{
                                                if(e.target.value === ''){
                                                    setValidateNewPasswordRepeat(false)
                                                }else{
                                                    setValidateNewPasswordRepeat(true)
                                                }
                                            }}
                                        id="simpleInput1"
                                        placeholder="Repeat New Password"
                                        autoComplete="off"
                                    />
                                    {validateNewPasswordRepeat === false ?(
                                            <div className="text-danger">
                                                Please enter the new Password Again
                                            </div>
                                        ):null}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="justify-content-center d-flex" style={{marginTop:'1.75rem'}}>
                        <button 
                            type="submit" 
                            className="btn btn-success"
                            disabled={oldPassword==='' || (newPassword === ''|| newPasswordRepeat === '')?true:false}  
                            onClick={handleSubmit}
                        >                   
                            Change Password
                        </button>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="mt-3">
                <h3>Change Profile Photo</h3>
                <img 
                    src={profileImage? URL.createObjectURL(profileImage) : src} 
                    alt="profile"
                    style={{height:'200px',width:'200px'}}    
                    />
                <div>
                    
                    {profileImage===''?
                    <>
                        <h5>Choose a profile photo</h5>
                        <input 
                            type="file" 
                            accept=".png, .jpg, .jpeg"
                            onChange={(event)=>{onFileChange(event)}}
                            style={{display:'block'}}
                            />
                    </>
                    
                    :<button 
                        className="btn btn-success mt-3" 
                        style={{marginLeft:'50px'}}
                        onClick={uploadFile}
                    >   
                        Upload
                    </button>
                    }
                    

                </div>
            </div>
        </Navbar>

    )
}

export default ChangePassword

