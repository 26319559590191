import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import Navbar from '../../../navbar'
import { Grid, _ } from "gridjs-react";
import { getVouchers } from '../../../../actions/promoCodes';
import { vouchersSelector } from '../../../../selector/promoCodes/vouchers';
import moment from 'moment';
import { useHistory } from 'react-router-dom';


const Table = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(()=>{
        dispatch(getVouchers())
    },[dispatch])
    const vouchers = useSelector((state)=>vouchersSelector(state))
    const data = vouchers?vouchers.map((voucher)=>[
        voucher.voucherId,
        voucher.offerName,
        voucher.type,
        voucher.type==='Discount'?`${voucher.discount}%`:_(<span>&#8377; {voucher.discount}</span>),
        moment(voucher.startDate).format('YYYY-MM-DD'),
        moment(voucher.endDate).format('YYYY-MM-DD'),
        _(
            voucher.status===1?
                <span className={`badge bg-danger`}>Not Availed</span>:
                <span className={`badge bg-success`}>Availed</span>
        ),
        _(
            <div 
                    className="col-2"
                    onClick={()=>{
                        history.push(`/updateVoucher/${voucher.voucherId}`)
                    }}
                >
                    <i className="mdi mdi-pencil text-warning font-20"></i>
            </div> 
        )

    ]):[]
    return (
        <div className="col-12">
        <div className="card">
            <div className="card-body">
                <div className="tab-content">
                    <div
                        className="tab-pane show active"
                        id="basic-datatable-preview"
                    >
                        <div id="basic-datatable-wrapper">
                            <Grid
                                data={data}
                                columns={[
                                    "Voucher Code",
                                    "Offer Name",
                                    "Type",
                                    "Discount",
                                    "Starts On",
                                    "Ends On",
                                    "status",
                                    "Action"
                                ]}
                                search={true}
                                pagination={{
                                    enabled: true,
                                    limit: 20,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}


const Vouchers = () => {
    return (
        <div>
            <Navbar pageTitle={"Vouchers"}>
                <Table/>
            </Navbar>
        </div>
    )
}

export default Vouchers