/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback, useRef,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Navbar from "../../navbar";
import { getAllUsers, setFilteredUsers, setFilter, } from "../../../actions/users";
import { usersSelector, filteredUsersSelector, filterSelector } from "../../../selector/users";
import moment from 'moment'
import { getPlans } from '../../../actions/plan';
import { planSelector } from '../../../selector/plan';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './ag-grid.css';
import Select from 'react-select';

const Action = (props) => {
    const history = useHistory();
    const userId = props.data.action

    return (
        <>
            <div className="row">
            <div className="col-3">
                    <i
                        onClick={() => {
                        history.push(`/userDetails/${userId}`);
                        }}
                        className="mdi mdi-account text-info"
                    ></i>

            </div> 
            <div className="col-3">
                    <i
                        onClick={() => {
                        history.push(`/newUser/${userId}`);
                    }} 
                        className="mdi mdi-pencil text-warning"
                    ></i>
            </div>
            {/* <div className="col-3">
                <i
                    className="mdi mdi-delete text-danger"
                ></i>
            </div> */}
            
            </div>
            
        </>
    )
}
const Table = ({filteredUsers}) => {
    const data = filteredUsers
        ? filteredUsers.map((user) => {
            return {
                userId: user.userId,
                userName: user.lastName !== null ? `${user.firstName} ${user.lastName}` : `${user.firstName}`,
                contact: user.callingNumber,
                planName: user.planName,
                startDate: moment(user.planStartDate).format('DD-MM-YYYY'),
                endDate: moment(user.planEndDate).format('DD-MM-YYYY'),
                remaining: (user.planName!=='Free Plan' && moment(user.planEndDate).diff(moment(),'days')>0) ?
                `${moment(user.planEndDate).diff(moment(),'days')}`: `∞`,          
                email: user.email,
                action: user.userId,
                nutritionCoach: user.nutritionCoach,
          }})
          : [];
          const [columnDefs] = useState([
              {headerName:"Actions", field:"action", cellRenderer:Action, resizable:true, sortable:true, filter:true, width:200},
              {headerName:"Name", field:"userName",  resizable:true, sortable:true, filter:true, width:200},
              {headerName:"Contact", field:"contact", resizable:true, sortable:true, filter:true, width:200},
              {headerName:"CurrentPlan", field:"planName", resizable:true, sortable:true, filter:true, width:200},
              {headerName:"Plan Start", field:"startDate",resizable:true, sortable:true, filter:true, width:160},
              {headerName:"Plan End", field:"endDate",resizable:true, sortable:true, filter:true, width:160},
              {headerName:"Remaining", field:"remaining", resizable:true, sortable:true, filter:true, width:150},
              {headerName:"Email", field:"email", width:200},
              {headerName:"Nutrition Coach", field:"nutritionCoach", width:200},
              {headerName:"User ID", field:"userId", resizable:true, sortable:true, filter:true, width:150}
          ])

        const gridRef = useRef();

        const onFilterTextBoxChanged = useCallback(() => {
            gridRef.current.api.setQuickFilter(
              document.getElementById('filter-text-box').value
            );
          }, []);
        
        //   const onPrintQuickFilterTexts = useCallback(() => {
        //     gridRef.current.api.forEachNode(function (rowNode, index) {
        //     });
        //   }, []);

          const onBtnExport = useCallback(() => {
            const exportCols = {
                columnKeys: ['userId','userName', 'contact', 'planName', 'email',],
                csvExportParams: {
                    processCellCallback: (params) => {
                      if (typeof params.value === 'string' && /^[=+\-@]/.test(params.value)) {
                        return `'${params.value}`; // prepending a single quote to mitigate CSV injection
                      }
                      return params.value;
                    }
                  }
            };
            
            gridRef.current.api.exportDataAsCsv(exportCols);
          }, []);

          const popupParent = useMemo(() => {
            return document.body;
          }, []);

        const getRowStyle = params => {
            return { fontFamily: "Cerebri Sans", fontSize: "0.9rem", border: "none", borderBottom: "1px solid #eef2f7"};
        };
        // font-family: "Cerebri Sans", sans-serif;
        // font-size: 0.9rem;
        // font-weight: 400;
        // line-height: 1.5;
        // color: #313a46;
        // background-color: #fafbfe;
        // -webkit-text-size-adjust: 100%;
        // -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div className="example-header">
                                <div class="gridjs-search">
                                    
                                </div>
                            </div>
                            <div id="basic-datatable-wrapper" className="ag-theme-material" style={{height: '100vh'}}>
                                <div className="row d-flex justify-content-between px-3">
                                    <input 
                                        type="text"
                                        id="filter-text-box"
                                        placeholder="Type a keyword..." 
                                        aria-label="Type a keyword..." 
                                        className="gridjs-input gridjs-search-input mb-2" 
                                        onInput={onFilterTextBoxChanged}

                                    />
                                    <a 
                                        className="btn btn-info ms-2"
                                        onClick={onBtnExport}
                                        style={{width:'45px', height:'45px'}}
                                        
                                        >
                                        <i class="dripicons-download"></i>
                                    </a>
                                </div>

                                <AgGridReact
                                    ref={gridRef}
                                    rowData={data}
                                    columnDefs={columnDefs}
                                    pagination={true} 
                                    paginationPageSize={20}
                                    cacheQuickFilter={true}
                                    suppressExcelExport={true}
                                    popupParent={popupParent}
                                    enableCellTextSelection={true}
                                    suppressCellSelection={true}
                                    getRowStyle={getRowStyle}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const UserListing = () => {
    const dispatch = useDispatch();

    const filter = useSelector((state) => filterSelector(state));

    const [planName, setPlanName] = useState(filter?filter.filterValue:'');
    const [acqChannel, setAcqChannel] = useState(filter?filter.filterValue:'');
    const [filterBy, setFilterBy] = useState(filter?filter.filterBy:'');

    useEffect(() => {
        dispatch(getAllUsers());
        dispatch(getPlans());
    }, [dispatch]);

    const users = Array.from(useSelector((state) => usersSelector(state)));
    const filteredUsers = useSelector((state) => filteredUsersSelector(state));
    const plans = useSelector((state) => planSelector(state))

    const handleAcqChannelSelector = (channelName) => {
        setAcqChannel(channelName);

        let filteredList = [];

        if(channelName.length !== 0)
        {
            users.forEach((user) => {
                channelName.forEach((item) => {
    
                    if(item.value === user.acqChannel)
                    {
                        filteredList.push(user)
                    }
                })
            })
        }
        else
        {
            filteredList = users
        }
        
        dispatch(setFilteredUsers(filteredList));
        dispatch(setFilter({filterBy: filterBy, filterValue: channelName}))
    }

    const handlePlanNameSelector = (plan) => {
        setPlanName(plan)

        let filteredList = []
        
        users.forEach((user) => {

            plan.forEach((item) => {

                if(item.value === user.planName)
                {
                    filteredList.push(user)
                }
            });
        })

        if(plan.length === 0)
        {
            filteredList = users
        }
        
        dispatch(setFilteredUsers(filteredList))
        dispatch(setFilter({filterBy: filterBy, filterValue: plan}))
    }

    const planNameSelector = () => {
        const options = plans ? plans.map((plan) => ({label: plan.planName, value: plan.planName})) : []
        return (
            <div className="">
                <Select
                    value={planName}
                    options={options}
                    emptyMessage="Not Found" 
                    placeholder="Select Plan"
                    onChange={e=>{handlePlanNameSelector(e)}} 
                    isMulti  
                />
            </div>
        )
    }

    const acqChannelSelector = () => {
        const list = ['App', 'Landing Checkout'];
        const options = list.map((item) => ({label: item, value: item}))
        return (
            <div className="">
                <Select
                    value={acqChannel}
                    options={options}
                    emptyMessage="Not Found" 
                    placeholder="Select Acquisition Channel"
                    onChange={e=>{handleAcqChannelSelector(e)}} 
                    isMulti
                />
            </div>
        )
    }

    const renderFilterValue = () => {
        if(filterBy === 'Plan Name')
        {
            return planNameSelector();
        }
        else if(filterBy === 'Acquisition Channel')
        {
            return acqChannelSelector();
        }
        else{
            return null;
        }
    }

    return (
        <div>
            <Navbar pageTitle={"User Database"}>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-start">
                                    <div className="row mb-1">
                                        <div className="col-10">
                                            <p className="header-title mt-0 mb-3">
                                                Apply Filter
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <button 
                                            className="btn btn-danger w-100"
                                            onClick={()=>{
                                                setFilterBy('')
                                                setPlanName('')
                                                setAcqChannel('')
                                                dispatch(setFilteredUsers(users))
                                                dispatch(setFilter({}))
                                            }}
                                            >
                                                Clear All
                                            </button> 
                                        </div>                                
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-3">
                                                    <label
                                                        htmlFor="filterBy"
                                                        className="col-form-label"
                                                    >
                                                        Filter By:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    <select
                                                        className="form-select"
                                                        id="led-by"
                                                        value={filterBy}
                                                        onChange={e=>setFilterBy(e.target.value)}
                                                    >
                                                        <option value="" disabled selected>Filter Users By</option>
                                                        <option>Plan Name</option>
                                                        <option>Acquisition Channel</option>
                                                    </select>
                                                </div>                                            
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-3">
                                                    <label
                                                        htmlFor="filterBy"
                                                        className="col-form-label"
                                                    >
                                                        Filter Value:
                                                    </label>
                                                </div>
                                                <div className="col-9">
                                                    {renderFilterValue()}
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Table filteredUsers={(planName || acqChannel) ? filteredUsers : users}/>
            </Navbar>
        </div>
    );
};

export default UserListing;
