import React,{useState,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { getChallengeDetails, setChallengeDetails } from '../../actions/challenges'
import { challengeDetailsSelector } from '../../selector/challenges/index'

const ChallengeLanding = ({handleChallengeLanding, challengeId, handleChallengeLandingPresignedUrl}) => {

    const dispatch = useDispatch()

    const [sectionList, setSectionList] = useState([{section: [{subSection: ""}]}])
    const [isImageAdded, setIsImageAdded] = useState([])

    useEffect(()=>{
        if(challengeId)
        {
            dispatch(getChallengeDetails(challengeId));
        }
        else{
            dispatch(setChallengeDetails([]))
        }
    },[dispatch, challengeId])

    const currentChallenge = useSelector(state=>challengeDetailsSelector(state))

    useEffect(() => {
        console.log(currentChallenge)
        if(currentChallenge.challengeLanding && currentChallenge.challengeLanding.length > 0)
        {
            const modifiedChallengeLanding = []
            currentChallenge.challengeLanding.forEach((item) => {
                const section = []
                if(Array.isArray(item))
                {
                    item.forEach((subItem) => {
                        section.push({subSection: subItem})
                    })
                    modifiedChallengeLanding.push({section})
                }
                else{
                    modifiedChallengeLanding.push({section: [{subSection: item}]})
                }   
            })
            setSectionList(modifiedChallengeLanding)
        }
        else{
            setSectionList([{section: [{subSection: ""}]}])
        }
        
    }, [currentChallenge])


    const handleOnChange = (sectionList) => {
        const landingUrl = [];
        const fileMap = new Map();

        sectionList.forEach(item => {
            if(item.section.length > 1)
            {
                const subUrl = []
                item.section.forEach((subItem) => {
                    if(typeof subItem.subSection === "string")
                    {
                        subUrl.push(subItem.subSection)
                    }
                    else{
                        subUrl.push({fileType: subItem.subSection.type, fileName: subItem.subSection.name})
                        fileMap.set(subItem.subSection.name, subItem.subSection)
                    }
                });

                landingUrl.push(subUrl)
            }
            else if(item.section[0])
            {
                if(typeof item.section[0].subSection === "string")
                {
                    landingUrl.push(item.section[0].subSection)
                }
                else{
                    fileMap.set(item.section[0].subSection.name, item.section[0].subSection)
                    landingUrl.push({fileType: item.section[0].subSection.type, fileName: item.section[0].subSection.name})
                }
            }
        })

        console.log(sectionList, landingUrl)
        handleChallengeLanding(landingUrl, fileMap)
    }

    return (
        <div className="h-100">
            <div className="row mx-2">
                <div className="justify-content-between d-flex" style={{marginTop:'1.75rem'}}>
                    <h3 className="d-flex justify-content-center">Challenge Landing</h3>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-7">
                            {
                                sectionList.map((item, index) => {

                                    return  (
                                        <div className='card'>
                                            <ul className='card-body' style={{listStyle: "none", whiteSpace: "nowrap", overflow: "auto"}}>
                                                {
                                                    item.section.map((subItem, subIndex) => (
                                                        <li style={{display: "inline-block"}}>
                                                            <div>
                                                                <input 
                                                                    type="file" 
                                                                    accept=".png, .jpg, .jpeg"
                                                                    onChange={(event) => {
                                                                        subItem.subSection = event.target.files[0]
                                                                        setSectionList([...sectionList])
                                                                        handleOnChange(sectionList)
                                                                        setIsImageAdded(isImageAdded.concat([event.target.files[0].name]));
                                                                    }}
                                                                    style={{display:'inline-block'}}
                                                                />
                                                                <i
                                                                    className="px-3"
                                                                    style={{fontStyle: 'normal'}}
                                                                    onClick={() => {
                                                                        if(item.section.length === 1)
                                                                        {
                                                                            sectionList.splice(index, 1);
                                                                        }
                                                                        else{
                                                                            sectionList[index] = {section: item.section.filter((subSection, i) => i !== subIndex)}
                                                                        }
                                                                        setSectionList([...sectionList])
                                                                        handleOnChange(sectionList)
                                                                        setIsImageAdded(isImageAdded.filter((image) => subItem.subSection.name !== image))
                                                                    }}
                                                                >&#10060;</i>
                                                            </div>
                                                            
                                                        </li>
                                                    ))
                                                }
                                                
                                                {
                                                    (item.section.length > 0) ?
                                                        <li style={{display: "inline-block"}}>
                                                            <i
                                                                className="uil-plus text-primary font-20"
                                                                onClick={() => {
                                                                    sectionList[index] = {section: item.section.concat([{subSection: ""}])}
                                                                    setSectionList([...sectionList])
                                                                }}
                                                            >
                                                            </i>
                                                        </li>
                                                    : <div></div>
                                                }
                                            </ul>
                                        </div>
                                )})
                            }
                        <div className="justify-content-center d-flex" style={{marginTop:'1.75rem'}}>
                            <button 
                                type="submit" 
                                className="btn btn-primary"
                                onClick={() => {
                                    setSectionList([...sectionList, {section: [{subSection: ""}]}])
                                }}
                            >   
                            Add New Section              
                            </button>
                            {
                                isImageAdded.length ? <button 
                                    type="submit" 
                                    className="btn btn-primary mx-2"
                                    onClick={() => {
                                        handleChallengeLandingPresignedUrl();
                                }}
                                >   
                                Upload            
                                </button> : <div></div>
                            }
                        </div>
                    </div>
                    <div className='col-5'>
                        {
                            sectionList.map((item) => (
                                <div style={{whiteSpace: "nowrap", overflow: "auto"}}>
                                    {
                                        item.section.map((subItem) => (
                                            <div style={{display: "inline-block"}}>
                                                <img 
                                                    src={typeof subItem.subSection === 'string' ? subItem.subSection : URL.createObjectURL(subItem.subSection)}
                                                    style={{height: "100%", width: "100%"}}
                                                    alt="challenge landing"
                                                />                            
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChallengeLanding;