export let ROOT_URL;
const domain = window.location.hostname;
console.log('--new-domain-logic--')
if (domain === 'admin.uvihealth.in') {
    ROOT_URL = "https://api.uvihealth.in";
} else if (domain === 'stageadmin.uvihealth.in') {
    ROOT_URL = "https://apistage.uvihealth.in";
} else {
    ROOT_URL = "http://localhost:3000";    
}

export const RAZORPAY_KEY_ID=`rzp_test_43Mz9yF0rRtBei`;