import React, { useEffect, useState, useCallback, useRef,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Navbar from "../../navbar";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { getAllDcotors, updateDoctorOnboarding, updateDoctorVerified } from "../../../actions/doctorFacingTech";
import { doctorsSelector } from "../../../selector/doctorFacingTech";
import Toggle from 'react-toggle'

const Action = (props) => {
    const history = useHistory();
    const doctorId = props.data.action

    return (
        <>
            <div className="row">
            <div className="col-3">
                    <i
                        onClick={() => {
                        history.push(`/updateDoctor/${doctorId}`);
                    }} 
                        className="mdi mdi-pencil text-warning"
                    ></i>
            </div>
            {/* <div className="col-3">
                <i
                    className="mdi mdi-delete text-danger"
                ></i>
            </div> */}
            
            </div>
            
        </>
    )
}

const Verified = (props) => {
    const dispatch = useDispatch();
    const doctorId = props.data.verified.doctorId
    const verified = props.data.verified.verified

    return (
        <>
            <div className="col-3">
                <Toggle
                    defaultChecked={verified===1 ? true : false}
                    onChange={() => dispatch(updateDoctorVerified({doctorId, verified: verified === 1 ? 0 : 1}))}
                />
            </div>
            
        </>
    )
}

const Onboarded = (props) => {
    const dispatch = useDispatch();
    const doctorId = props.data.onboarded.doctorId
    const onboarded = props.data.onboarded.onboarded

    return (
        <>
            <div className="col-3">
                <Toggle
                    defaultChecked={onboarded===1 ? true : false}
                    onChange={() => dispatch(updateDoctorOnboarding({doctorId, onboarded: onboarded === 1 ? 0 : 1}))}
                />
            </div>
            
        </>
    )
}

const Table = ({doctors}) => {

    const history = useHistory();
    
        const data = doctors
        ? doctors.map((doctor) => {
            return {
                doctor_id: doctor.doctor_id,
                name: doctor.name,
                calling_number: doctor.calling_number,
                whatsapp_number: doctor.whatsapp_number,
                email: doctor.email,
                clinic_name: doctor.clinic_name,
                clinic_address: doctor.clinic_address,
                gender: doctor.gender,
                specialisation: doctor.specialisation,
                rmp_number: doctor.rmp_number,
                action: doctor.doctor_id,
                verified: {doctorId: doctor.doctor_id, verified: doctor.verified},
                onboarded: {doctorId: doctor.doctor_id, onboarded: doctor.onboarded}
        }})
        : [];

        const [columnDefs] = useState([
            {headerName:"Actions", field:"action", cellRenderer: Action, resizable:true, sortable:true, filter:true, width:200},
            {headerName:"Name", field:"name",  resizable:true, sortable:true, filter:true, width:200},
            {headerName:"Verified", field:"verified", cellRenderer: Verified, resizable:true, sortable:true, filter:true, width:180},
            {headerName:"Onboarded", field:"onboarded", cellRenderer: Onboarded, resizable:true, sortable:true, filter:true, width:180},
            {headerName:"Calling Number", field:"calling_number", resizable:true, sortable:true, filter:true, width:200},
            {headerName:"Whatsapp Number", field:"whatsapp_number", resizable:true, sortable:true, filter:true, width:200},
            {headerName:"Email", field:"email",resizable:true, sortable:true, filter:true, width:200},
            {headerName:"Clinic Name", field:"clinic_name",resizable:true, sortable:true, filter:true, width:200},
            {headerName:"Clinic Address", field:"clinic_address", resizable:true, sortable:true, filter:true, width:250},
            {headerName:"Gender", field:"gender", width:150},
            {headerName:"Specialisation", field:"specialisation", width:200},
            {headerName:"RMP Number", field:"rmp_number", resizable:true, sortable:true, filter:true, width:150},
            {headerName:"Doctor ID", field:"doctor_id", resizable:true, sortable:true, filter:true, width:150}
        ])

        const gridRef = useRef();

        const onFilterTextBoxChanged = useCallback(() => {
            gridRef.current.api.setQuickFilter(
              document.getElementById('filter-text-box').value
            );
          }, []);

          const onBtnExport = useCallback(() => {
            const exportCols = {
                columnKeys: ['doctor_id','name', 'calling_number', 'whatsapp_number', 'email',"clinic_name", "clinic_address", "gender","specialisation", "rmp_number"],
                csvExportParams: {
                    processCellCallback: (params) => {
                      if (typeof params.value === 'string' && /^[=+\-@]/.test(params.value)) {
                        return `'${params.value}`; // prepending a single quote to mitigate CSV injection
                      }
                      return params.value;
                    }
                  }
            };
            gridRef.current.api.exportDataAsCsv(exportCols);
          }, []);

          const popupParent = useMemo(() => {
            return document.body;
          }, []);

        const getRowStyle = params => {
            return { fontFamily: "Cerebri Sans", fontSize: "0.9rem", border: "none", borderBottom: "1px solid #eef2f7"};
        };
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="tab-content">
                        <div
                            className="tab-pane show active"
                            id="basic-datatable-preview"
                        >
                            <div className="example-header">
                                <div class="gridjs-search">
                                    
                                </div>
                            </div>
                            <div id="basic-datatable-wrapper" className="ag-theme-material" style={{height: '100vh'}}>
                                <div className="row d-flex justify-content-between px-3">
                                    <div className="col d-flex justify-content-between px-3">
                                        <input 
                                            type="text"
                                            id="filter-text-box"
                                            placeholder="Type a keyword..." 
                                            aria-label="Type a keyword..." 
                                            className="gridjs-input gridjs-search-input mb-2" 
                                            onInput={onFilterTextBoxChanged}

                                        />
                                        <div>
                                            <button 
                                                className="btn btn-success"
                                                style={{width:'200px', height:'45px'}}
                                                onClick={() => {
                                                    history.push('/createDoctor')
                                                }}
                                            > 
                                                    Create Doctor
                                            </button>
                                            <a 
                                                className="btn btn-info ms-2"
                                                onClick={onBtnExport}
                                                style={{width:'45px', height:'45px'}}
                                                
                                                >
                                                <i class="dripicons-download"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <AgGridReact
                                    ref={gridRef}
                                    rowData={data}
                                    columnDefs={columnDefs}
                                    pagination={true} 
                                    paginationPageSize={20}
                                    cacheQuickFilter={true}
                                    suppressExcelExport={true}
                                    popupParent={popupParent}
                                    enableCellTextSelection={true}
                                    suppressCellSelection={true}
                                    getRowStyle={getRowStyle}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const DoctorsListing = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllDcotors())
    }, [dispatch]);

    const doctors = useSelector((state) => doctorsSelector(state));
    console.log(doctors)


    return (
        <div>
            <Navbar pageTitle={"Doctors"}>
                <Table doctors={doctors}/>
            </Navbar>
        </div>
    );
};

export default DoctorsListing ;